import { useRoutes } from "react-router-dom";

// Routes Array
import LoginRoutes from './LoginRoutes';
import SuperAdminRoutes from './SuperAdminRoutes';
import VendorRoutes from "./VendorRoutes";

export default function Router() {
  return useRoutes(
    [
      LoginRoutes,
      SuperAdminRoutes,
      VendorRoutes
    ]
  );
}