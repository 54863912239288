/* eslint-disable arrow-body-style */
import { postMethod } from "../apiConfig";

export const getRoleModulesByRolesApi = (data) => {
    return postMethod('/role-module/fetch-modules-by-role', data);
};

export const addUpdateRolePermissionApi = (data) => {
    return postMethod('/role-module/add-update-permissions', data);
};
/* eslint-disable arrow-body-style */