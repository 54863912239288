import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from '../redux/store';
import NoPermissionPage from '../pages/NoPermissionPage';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

SuperAdminGuard.propTypes = {
    children: PropTypes.node,
};

export default function SuperAdminGuard({ children }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { rolemodules } = useSelector((state) => state.roleaccess);

    const modules = rolemodules
        ?.flatMap((item) => item?.Modules?.flatMap((item) => item?.childrens?.map((item) => item?.path)))
        .filter((item) => item !== undefined);

    modules?.push(
        '/super-admin/user-management/roles',
        '/super-admin/dashboard',
        '/super-admin/page-content',
        '/super-admin/profile',
        '/super-admin/global-settings',
        '/super-admin/user-task',
        '/super-admin/support',
        '/super-admin/master/faqs',
        '/super-admin/master/coupons/sub/:createdAt',
        '/super-admin/master/coupons/sub/edit',
        "/super-admin/master/event-types", 
        "/super-admin/master/styles",
        "/super-admin/master/addons",
        "/super-admin/master/price-tiers",
        "/super-admin/master/guest-no-costs",
        "/super-admin/master/cities",
        "/super-admin/master/bookings",
        "/super-admin/master/guest-range",
        "/super-admin/master/duration-range",
        "/super-admin/master/testimonial",
        "/super-admin/master/supports",
        "/super-admin/master/packages",
        "/super-admin/master/services"
        );
        
    const isRouteAllowed = (route) => {
        if (pathname === '/') {
            navigate(PATH_DASHBOARD.superAdminRoot, { replace: true });
            return true;
        }

        const convertedRoute = route?.replace(/s$/, '');
        return pathname.startsWith(route) || pathname.startsWith(`${convertedRoute}/`);
    };

    if (!modules?.some((item) => isRouteAllowed(item))) {
        return <NoPermissionPage />;
    }

    return <>{children}</>;
}
