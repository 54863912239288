import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { user, isAuthenticated } = useAuth();
  
  if (isAuthenticated && user?.UserRole?.role === 'SuperAdmin') {
    return <Navigate to={PATH_DASHBOARD.superAdminRoot} />;
  }
  if (isAuthenticated && user?.UserRole?.role === 'Vendor') {
    return <Navigate to={PATH_DASHBOARD.vendorRoot} />;
  }
  // if (isAuthenticated) {
  //   return <Navigate to={PATH_DASHBOARD.root} />;
  // }
  return <>{children}</>;
}