import { lazy, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import VendorLayout from '../layouts/vendor/dashboard';
import AuthGuard from '../guards/AuthGuard';
import VendorGuard from '../guards/VendorGuard';
import { VENDOR_DASHBOARD } from '../config';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/vendor/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

const Dashboard = Loadable(lazy(() => import('../pages/users/vendor/dashboard/Dashboard')));

const VendorRoutes = {
    path: "/vendor",
    element: (
        <AuthGuard>
            <VendorGuard>
                <VendorLayout />
            </VendorGuard>
        </AuthGuard>
    ),
    children: [
        { element: <Navigate to={VENDOR_DASHBOARD} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
    ]
};

export default VendorRoutes;