import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { user, isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInitialized) {
      return <LoadingScreen />;
    }
    
    if (!isAuthenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      navigate('/', { replace: true });
    }

    
    if(isAuthenticated && user?.UserRole?.role === 'SuperAdmin') {
      if(pathname.includes('/super-admin')) {
        return <>{children}</>;
      }
      window.location.href = PATH_DASHBOARD.superAdminRoot;

    } 
    if(isAuthenticated && user?.UserRole?.role === 'Vendor') {
      if(pathname.includes('/vendor')) {
        return <>{children}</>;
      }
      window.location.href = PATH_DASHBOARD.vendorRoot;
    }
  }, [user, isAuthenticated, isInitialized])


  // if (requestedLocation && pathname !== requestedLocation) {
  //   setRequestedLocation(null);
  //   return <Navigate to={requestedLocation} />;
  // }
  
  return <>{children}</>;
}